import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

const InputTinyMce = ({ dataValue, onChangeData }) => {
  const textToHtml = (text) => {
    if (text.length > 0) {
      const elem = document.createElement("div");
      return text
        .split(/\n\n+/)
        .map((paragraph) => {
          return (
            "<p>" +
            paragraph
              .split(/\n+/)
              .map((line) => {
                elem.textContent = line;
                return elem.innerHTML;
              })
              .join("<br/>") +
            "</p>"
          );
        })
        .join("");
    }
  };

  return (
    <>
      <Editor
        apiKey="0umeik3vq3bou8smm071ml2ejctylj663mwen87lxd667su7"
        value={dataValue}
        outputFormat="text"
        onEditorChange={(e) => onChangeData(e)}
        //onInit={(evt, editor) => editorRef.current = editor}

        init={{
          // height: 500,
          //menubar: false,
          //plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate mentions tableofcontents footnotes mergetags autocorrect typography inlinecss',
          //toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | removeformat code',
          menubar: true,
          plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
          toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | addcomment showcomments | align lineheight | numlist bullist indent outdent | removeformat code',
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            base_url: 'https://cdn.tiny.cloud/1/your-api-key/tinymce/6', // Use TinyMCE's CDN
            suffix: '.min', // Use minified files
        }}
      />
      {/* <button onClick={log}>Log editor content</button> */}
    </>
  );
};

export default InputTinyMce;
